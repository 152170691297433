@import '../../scss/vars';
@import '../../scss/_utils';

/* List View */
.Episode-list {
  margin-left: -16px;
  margin-right: -16px;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px 22px;
  border: 1px solid rgba($primary, 0);

  .ep-thumb {
    max-width: 42px;
    margin-right: 21px;
  }

  .ep-prime {
    line-height: 19px;
    flex: 1;
  }

  .ep-duration {
    margin-left: 20px;
  }

  &:hover,
  &.playing {
    border-color: rgba($primary, 1);
  }
}

/* Card View */
.Episode-Card {
  .glass {
    padding: 10px 21px;

    .caption-upper {
      .duration {
        &:before {
          margin-left: 5px;
          margin-right: 5px;
          content: "\02022"
        }
      }
    }
  }
}

/* Row view */
.Episode-Row {
  font-family: 'Work Sans', sans-serif;

  .Episode-Row-header {

    padding: 0 0 37px 20px;

    .heading {
      .meta {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #EEEEEE;

        .date {
          padding-right: 11px;
        }

        .duration {
          padding-left: 10px;
        }
      }

      .actions {
        button {
          @include button("gray");
          margin-right: 4vw;

          &:last-of-type {
            margin-right: 0;
          }

          i {
            font-size: 24px;
          }

          span {
            padding-left: 8px;
          }
        }
      }
    }

    .title {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;

      color: #FFFFFF;
    }

    .author {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: #FFFFFF;
    }

    .description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */
      color: #FFFFFF;
      padding-top: 13px;
      padding-bottom: 3px;
    }
  }

}
