.ShareButtons{
    display: flex;
    flex-direction: column;
    
    .ShareBtn{
        border: 0;
        background: transparent;
        padding: 20px 0;
        text-align: center;
        color: #fff;
    }
    
}