@import 'vars';
@import 'typography';
@import 'utils';
@import 'card';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  background-color: $bg;
}
small {
  font-size: 14px;
}
strong, .strong {
  font-weight: bold;
}
img {
  max-width: 100%;
  height: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-layout-content {
  padding: 0 30px;
}

.layout {
  display: grid;
  grid-template-columns: 86px 1fr;
  grid-template-rows: 1fr 100px;

  &.open {
    grid-template-columns: 240px 1fr;
  }
}
.block {
  margin: 15px 0;
}
aside {
  padding: 0 35px 25px;
}
.pointer {cursor: pointer;}
.workspace {
  display: grid;
  column-gap: 35px;
  padding-bottom: 35px
}
.page {
  padding: 0px 70px 0;
}
.workspace.home {
  padding-left: 70px;
  padding-right: 90px;
  grid-template-columns: 1fr 1fr;
}
.text-right {text-align: right;}

.ant-card-bordered {
  background-color: rgba(255,255,255, .15);
  color: #fff;
}
.ant-card-head-title {color: #fff;}
header {
  padding: 20px;
}
.text-grad {
  background: linear-gradient(to bottom, #04F9AB 0%, #63DAFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}