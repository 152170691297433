@import 'vars';


/* Import google font */
// All imports only at the top of the file (delete in the next commit ;))
@import url("https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap");

.smoked {
  background-color: rgba(#fff, .1);
}

.glass {
  position: relative;
  overflow: hidden;
  background: inherit;
  background-color: rgba(#000, .7);
  backdrop-filter: blur(5px);
}

.bold {
  font-weight: bold;
}

.accent {
  color: $primary;
}

.no-mar-b {
  margin-bottom: 0;
}

.z-top {
  z-index: 10;
}

.fg {
  background: #3F4043;
}

.fw-bg,
.fw-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.fw-bg {
    background-position: center center;
    background-size: cover;
  }

  &.fw-area {
    cursor: pointer;
  }
}

.divider {
  height: 1px;
  background-color: rgba(#fff, .2);
}

.radius {
  &-8 {
    border-radius: 8px;
  }

  &-30 {
    border-radius: 30px;
  }
}

.mb {
  &-15 {
    margin-bottom: 15px;
  }
}

/* link -> */
.arrow-link {
  cursor: pointer;
  color: $primary;

  &:after {
    background: url(../assets/arrow-link.svg) 0 0 no-repeat;
    content: '';
    display: inline-block;
    width: 13px;
    height: 10px;
    margin-left: 5px;
    transition: transform .3s ease;
  }

  &:hover {
    color: $primary;

    &:after {
      transform: translateX(2px);
    }
  }
}

/* flex-box stuff */
.flex {
  display: flex;

  &.v-center {
    align-items: center;
  }

  .flex-1 {
    flex: 1;
  }

  &.j-between {
    justify-content: space-between;
  }
}

/* ratio-box - a block that maintains aspect ratio. 1:1 by default*/
.ratio-box {
  position: relative;

  &:after {
    z-index: -1;
    content: '';
    padding-bottom: 100%;
    display: block;
  }

  &.r16-9:after {
    padding-bottom: 56.25%;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

/* Button mixin */
@mixin button($colorScheme) {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border: 0;
  justify-content: center;

  &:active,
  &:focus {
    outline: none;
  }

  @if $colorScheme=="yellow" {
    color: #2A2B2E;
    background: #FFBE01;
    mix-blend-mode: normal;
  }

  @if $colorScheme=="gray" {
    color: rgba(196, 196, 196, 0.6);
    background: transparent;
    mix-blend-mode: normal;
  }
  
  @if $colorScheme=="transparent-yellow" {
    color: #FFBE01;
    background: transparent;
    mix-blend-mode: normal;
  }  
  
}
