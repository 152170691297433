@import '../../scss/_utils';

/* subscription form */
.subscribe-block {

  h2 {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-transform: capitalize;
    color: #FFBE01;
    mix-blend-mode: normal;
  }

  .subscription-form {

    .ant-input {
      border: 0;
      height: 42px;
      background: rgba(255, 255, 255, 0.1);
      mix-blend-mode: normal;
      border-radius: 8px 0px 0px 8px;
      color: rgba(255, 255, 255, 0.6);

      &:active,
      &:focus {
        outline: none;
        box-shadow: none;
      }

    }

    .ant-btn {
      @include button("yellow");
      width: 110px;
      height: 42px;
      border-radius: 0px 8px 8px 0px;
    }

    .ant-input-group-addon {
      background: transparent;
    }

  }
}