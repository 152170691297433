@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,700,800&display=swap);
h1, h2, h3, h4, h5, h6 {
  color: #fff; }

h1 {
  font-weight: bold; }

h6 {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  margin: 0 0 8px; }

/* Import google font */
.smoked {
  background-color: rgba(255, 255, 255, 0.1); }

.glass {
  position: relative;
  overflow: hidden;
  background: inherit;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); }

.bold {
  font-weight: bold; }

.accent {
  color: #FFBE01; }

.no-mar-b {
  margin-bottom: 0; }

.z-top {
  z-index: 10; }

.fg {
  background: #3F4043; }

.fw-bg,
.fw-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .fw-bg.fw-bg,
  .fw-area.fw-bg {
    background-position: center center;
    background-size: cover; }
  .fw-bg.fw-area,
  .fw-area.fw-area {
    cursor: pointer; }

.divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2); }

.radius-8 {
  border-radius: 8px; }

.radius-30 {
  border-radius: 30px; }

.mb-15 {
  margin-bottom: 15px; }

/* link -> */
.arrow-link {
  cursor: pointer;
  color: #FFBE01; }
  .arrow-link:after {
    background: url(/static/media/arrow-link.1efc33a3.svg) 0 0 no-repeat;
    content: '';
    display: inline-block;
    width: 13px;
    height: 10px;
    margin-left: 5px;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease; }
  .arrow-link:hover {
    color: #FFBE01; }
    .arrow-link:hover:after {
      -webkit-transform: translateX(2px);
              transform: translateX(2px); }

/* flex-box stuff */
.flex {
  display: flex; }
  .flex.v-center {
    align-items: center; }
  .flex .flex-1 {
    flex: 1 1; }
  .flex.j-between {
    justify-content: space-between; }

/* ratio-box - a block that maintains aspect ratio. 1:1 by default*/
.ratio-box {
  position: relative; }
  .ratio-box:after {
    z-index: -1;
    content: '';
    padding-bottom: 100%;
    display: block; }
  .ratio-box.r16-9:after {
    padding-bottom: 56.25%; }
  .ratio-box .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

/* Button mixin */
.__card {
  position: relative;
  border-radius: 30px;
  overflow: hidden; }
  .__card .__caption {
    background: inherit;
    position: relative;
    border-radius: 0 0 30px 30px;
    z-index: 2; }
    .__card .__caption:before {
      content: '';
      display: block;
      height: 0;
      padding-bottom: 39.603960396%; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  background-color: #2A2B2E; }

small {
  font-size: 14px; }

strong, .strong {
  font-weight: bold; }

img {
  max-width: 100%;
  height: auto; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.ant-layout-content {
  padding: 0 30px; }

.layout {
  display: grid;
  grid-template-columns: 86px 1fr;
  grid-template-rows: 1fr 100px; }
  .layout.open {
    grid-template-columns: 240px 1fr; }

.block {
  margin: 15px 0; }

aside {
  padding: 0 35px 25px; }

.pointer {
  cursor: pointer; }

.workspace {
  display: grid;
  grid-column-gap: 35px;
  -webkit-column-gap: 35px;
     -moz-column-gap: 35px;
          column-gap: 35px;
  padding-bottom: 35px; }

.page {
  padding: 0px 70px 0; }

.workspace.home {
  padding-left: 70px;
  padding-right: 90px;
  grid-template-columns: 1fr 1fr; }

.text-right {
  text-align: right; }

.ant-card-bordered {
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff; }

.ant-card-head-title {
  color: #fff; }

header {
  padding: 20px; }

.text-grad {
  background: -webkit-gradient(linear, left top, left bottom, from(#04F9AB), to(#63DAFF));
  background: linear-gradient(to bottom, #04F9AB 0%, #63DAFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

Nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  overflow: auto; }
  Nav .anticon {
    font-size: 22px; }
  Nav main {
    flex: 1 1;
    padding-top: 100px; }
  Nav footer {
    padding-bottom: 30px; }
    Nav footer .toggle {
      padding-left: 30px; }
  Nav .ant-menu.ant-menu-vertical {
    padding: 0 15px;
    background-color: transparent;
    border-right: none; }
    Nav .ant-menu.ant-menu-vertical .ant-menu-item {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 22px; }
      Nav .ant-menu.ant-menu-vertical .ant-menu-item i {
        margin-right: 20px;
        font-size: 22px; }
      Nav .ant-menu.ant-menu-vertical .ant-menu-item:active {
        background: transparent; }
      Nav .ant-menu.ant-menu-vertical .ant-menu-item a:hover {
        color: #FFBE01; }
      Nav .ant-menu.ant-menu-vertical .ant-menu-item-selected {
        background: transparent; }
        Nav .ant-menu.ant-menu-vertical .ant-menu-item-selected a {
          color: #FFBE01; }
    Nav .ant-menu.ant-menu-vertical a {
      color: #fff; }
  Nav .divider {
    margin: 30px 0; }

.Search {
  position: relative;
  margin-left: 20px;
  padding-right: 20px;
  cursor: pointer; }
  .open .Search {
    cursor: auto;
    cursor: initial; }
  .Search .magnifier {
    pointer-events: none;
    position: absolute;
    left: 13px;
    top: 3px;
    z-index: 1; }
  .Search .ant-select {
    display: block; }
    .Search .ant-select-selection {
      background-color: transparent;
      opacity: 0; }
      .open .Search .ant-select-selection {
        opacity: 1; }
      .Search .ant-select-selection__placeholder {
        left: 30px; }
  .Search .ant-select-auto-complete.ant-select .ant-input {
    background: none;
    color: #fff;
    padding-left: 40px; }

.ShareButtons {
  display: flex;
  flex-direction: column; }
  .ShareButtons .ShareBtn {
    border: 0;
    background: transparent;
    padding: 20px 0;
    text-align: center;
    color: #fff; }

#player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: -1px 0 10px rgba(0, 0, 0, 0.3);
  color: white;
  padding: 30px; }

.TrackInfo {
  background: transparent;
  flex-shrink: 0; }
  .TrackInfo .ant-card-body {
    padding: 0; }
  .TrackInfo .ant-card-meta-avatar .ant-avatar {
    box-shadow: 0px 1px 4px rgba(4, 249, 171, 0.15);
    border-radius: 8px;
    width: 64px;
    height: 64px; }
  .TrackInfo .ant-card-meta-detail .ant-card-meta-title {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    color: #FFFFFF; }
  .TrackInfo .ant-card-meta-detail .ant-card-meta-description {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
    margin-top: -10px; }

.PlayerControls {
  display: flex;
  padding-left: 4vw;
  padding-top: 15px; }
  .PlayerControls .ProgressBar {
    width: 37vw;
    margin: 0 15px;
    padding-top: 3px; }
    .PlayerControls .ProgressBar .ant-progress-inner {
      background: #C4C4C4;
      border-radius: 5px;
      height: 6px; }
      .PlayerControls .ProgressBar .ant-progress-inner .ant-progress-bg {
        background: #FFBE01;
        mix-blend-mode: normal;
        border-radius: 5px; }
  .PlayerControls .PlayButton {
    margin: 0 20px; }
  .PlayerControls .TimeElapsed,
  .PlayerControls .TimeDuration {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #EEEEEE;
    padding-top: 7px;
    width: 30px;
    text-align: center; }
  .PlayerControls .TimeElapsed {
    margin-left: 35px; }
  .PlayerControls .Prev10,
  .PlayerControls .PlayButton,
  .PlayerControls .Next10,
  .PlayerControls .DownloadButton,
  .PlayerControls .ShareButton {
    display: flex;
    justify-content: center;
    background: transparent;
    border: 0;
    color: #FFBE01;
    font-size: 32px; }
  .PlayerControls .DownloadButton {
    font-size: 24px; }
  .PlayerControls .ShareButton {
    color: #EEEEEE;
    font-size: 20px; }
  .PlayerControls .DownloadButton {
    margin-left: 28px;
    margin-right: 16px; }

.PlayerAudio {
  display: none; }

.Show-card .glass {
  padding: 20px; }

.carousel.carousel-slider {
  padding-top: 46px;
  margin-top: -38px; }
  .carousel.carousel-slider .control-arrow {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-size: contain;
    position: absolute;
    top: 0; }
    .carousel.carousel-slider .control-arrow:before {
      display: none; }
    .carousel.carousel-slider .control-arrow:hover {
      background: inherit;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      background-size: contain; }
    .carousel.carousel-slider .control-arrow.control-prev {
      right: 62px;
      left: auto;
      background-image: url(/static/media/left-circle.6b2dfbec.svg); }
    .carousel.carousel-slider .control-arrow.control-next {
      right: 0;
      background-image: url(/static/media/right-circle.06f56553.svg); }

.FeaturedEpisode {
  -webkit-transition: all ease .3s;
  transition: all ease .3s;
  border: 1px solid #2A2B2E; }
  .FeaturedEpisode:hover {
    border-color: #FFBE01; }
  .FeaturedEpisode .glass {
    padding: 20px;
    border-radius: 0 0 28px 28px; }
  .FeaturedEpisode .ep-caption .duration {
    margin-left: 10px; }

/* Import google font */
.smoked {
  background-color: rgba(255, 255, 255, 0.1); }

.glass {
  position: relative;
  overflow: hidden;
  background: inherit;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); }

.bold {
  font-weight: bold; }

.accent {
  color: #FFBE01; }

.no-mar-b {
  margin-bottom: 0; }

.z-top {
  z-index: 10; }

.fg {
  background: #3F4043; }

.fw-bg,
.fw-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .fw-bg.fw-bg,
  .fw-area.fw-bg {
    background-position: center center;
    background-size: cover; }
  .fw-bg.fw-area,
  .fw-area.fw-area {
    cursor: pointer; }

.divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2); }

.radius-8 {
  border-radius: 8px; }

.radius-30 {
  border-radius: 30px; }

.mb-15 {
  margin-bottom: 15px; }

/* link -> */
.arrow-link {
  cursor: pointer;
  color: #FFBE01; }
  .arrow-link:after {
    background: url(/static/media/arrow-link.1efc33a3.svg) 0 0 no-repeat;
    content: '';
    display: inline-block;
    width: 13px;
    height: 10px;
    margin-left: 5px;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease; }
  .arrow-link:hover {
    color: #FFBE01; }
    .arrow-link:hover:after {
      -webkit-transform: translateX(2px);
              transform: translateX(2px); }

/* flex-box stuff */
.flex {
  display: flex; }
  .flex.v-center {
    align-items: center; }
  .flex .flex-1 {
    flex: 1 1; }
  .flex.j-between {
    justify-content: space-between; }

/* ratio-box - a block that maintains aspect ratio. 1:1 by default*/
.ratio-box {
  position: relative; }
  .ratio-box:after {
    z-index: -1;
    content: '';
    padding-bottom: 100%;
    display: block; }
  .ratio-box.r16-9:after {
    padding-bottom: 56.25%; }
  .ratio-box .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

/* Button mixin */
/* List View */
.Episode-list {
  margin-left: -16px;
  margin-right: -16px;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px 22px;
  border: 1px solid rgba(255, 190, 1, 0); }
  .Episode-list .ep-thumb {
    max-width: 42px;
    margin-right: 21px; }
  .Episode-list .ep-prime {
    line-height: 19px;
    flex: 1 1; }
  .Episode-list .ep-duration {
    margin-left: 20px; }
  .Episode-list:hover, .Episode-list.playing {
    border-color: #ffbe01; }

/* Card View */
.Episode-Card .glass {
  padding: 10px 21px; }
  .Episode-Card .glass .caption-upper .duration:before {
    margin-left: 5px;
    margin-right: 5px;
    content: "\02022"; }

/* Row view */
.Episode-Row {
  font-family: 'Work Sans', sans-serif; }
  .Episode-Row .Episode-Row-header {
    padding: 0 0 37px 20px; }
    .Episode-Row .Episode-Row-header .heading .meta {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #EEEEEE; }
      .Episode-Row .Episode-Row-header .heading .meta .date {
        padding-right: 11px; }
      .Episode-Row .Episode-Row-header .heading .meta .duration {
        padding-left: 10px; }
    .Episode-Row .Episode-Row-header .heading .actions button {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      border: 0;
      justify-content: center;
      color: rgba(196, 196, 196, 0.6);
      background: transparent;
      mix-blend-mode: normal;
      margin-right: 4vw; }
      .Episode-Row .Episode-Row-header .heading .actions button:active, .Episode-Row .Episode-Row-header .heading .actions button:focus {
        outline: none; }
      .Episode-Row .Episode-Row-header .heading .actions button:last-of-type {
        margin-right: 0; }
      .Episode-Row .Episode-Row-header .heading .actions button i {
        font-size: 24px; }
      .Episode-Row .Episode-Row-header .heading .actions button span {
        padding-left: 8px; }
    .Episode-Row .Episode-Row-header .title {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF; }
    .Episode-Row .Episode-Row-header .author {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF; }
    .Episode-Row .Episode-Row-header .description {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */
      color: #FFFFFF;
      padding-top: 13px;
      padding-bottom: 3px; }

/* Import google font */
.smoked {
  background-color: rgba(255, 255, 255, 0.1); }

.glass {
  position: relative;
  overflow: hidden;
  background: inherit;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); }

.bold {
  font-weight: bold; }

.accent {
  color: #FFBE01; }

.no-mar-b {
  margin-bottom: 0; }

.z-top {
  z-index: 10; }

.fg {
  background: #3F4043; }

.fw-bg,
.fw-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .fw-bg.fw-bg,
  .fw-area.fw-bg {
    background-position: center center;
    background-size: cover; }
  .fw-bg.fw-area,
  .fw-area.fw-area {
    cursor: pointer; }

.divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2); }

.radius-8 {
  border-radius: 8px; }

.radius-30 {
  border-radius: 30px; }

.mb-15 {
  margin-bottom: 15px; }

/* link -> */
.arrow-link {
  cursor: pointer;
  color: #FFBE01; }
  .arrow-link:after {
    background: url(/static/media/arrow-link.1efc33a3.svg) 0 0 no-repeat;
    content: '';
    display: inline-block;
    width: 13px;
    height: 10px;
    margin-left: 5px;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease; }
  .arrow-link:hover {
    color: #FFBE01; }
    .arrow-link:hover:after {
      -webkit-transform: translateX(2px);
              transform: translateX(2px); }

/* flex-box stuff */
.flex {
  display: flex; }
  .flex.v-center {
    align-items: center; }
  .flex .flex-1 {
    flex: 1 1; }
  .flex.j-between {
    justify-content: space-between; }

/* ratio-box - a block that maintains aspect ratio. 1:1 by default*/
.ratio-box {
  position: relative; }
  .ratio-box:after {
    z-index: -1;
    content: '';
    padding-bottom: 100%;
    display: block; }
  .ratio-box.r16-9:after {
    padding-bottom: 56.25%; }
  .ratio-box .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

/* Button mixin */
/* subscription form */
.subscribe-block h2 {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
  color: #FFBE01;
  mix-blend-mode: normal; }

.subscribe-block .subscription-form .ant-input {
  border: 0;
  height: 42px;
  background: rgba(255, 255, 255, 0.1);
  mix-blend-mode: normal;
  border-radius: 8px 0px 0px 8px;
  color: rgba(255, 255, 255, 0.6); }
  .subscribe-block .subscription-form .ant-input:active, .subscribe-block .subscription-form .ant-input:focus {
    outline: none;
    box-shadow: none; }

.subscribe-block .subscription-form .ant-btn {
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  border: 0;
  justify-content: center;
  color: #2A2B2E;
  background: #FFBE01;
  mix-blend-mode: normal;
  width: 110px;
  height: 42px;
  border-radius: 0px 8px 8px 0px; }
  .subscribe-block .subscription-form .ant-btn:active, .subscribe-block .subscription-form .ant-btn:focus {
    outline: none; }

.subscribe-block .subscription-form .ant-input-group-addon {
  background: transparent; }


/* Import google font */
.smoked {
  background-color: rgba(255, 255, 255, 0.1); }

.glass {
  position: relative;
  overflow: hidden;
  background: inherit;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px); }

.bold {
  font-weight: bold; }

.accent {
  color: #FFBE01; }

.no-mar-b {
  margin-bottom: 0; }

.z-top {
  z-index: 10; }

.fg {
  background: #3F4043; }

.fw-bg,
.fw-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .fw-bg.fw-bg,
  .fw-area.fw-bg {
    background-position: center center;
    background-size: cover; }
  .fw-bg.fw-area,
  .fw-area.fw-area {
    cursor: pointer; }

.divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2); }

.radius-8 {
  border-radius: 8px; }

.radius-30 {
  border-radius: 30px; }

.mb-15 {
  margin-bottom: 15px; }

/* link -> */
.arrow-link {
  cursor: pointer;
  color: #FFBE01; }
  .arrow-link:after {
    background: url(/static/media/arrow-link.1efc33a3.svg) 0 0 no-repeat;
    content: '';
    display: inline-block;
    width: 13px;
    height: 10px;
    margin-left: 5px;
    -webkit-transition: -webkit-transform .3s ease;
    transition: -webkit-transform .3s ease;
    transition: transform .3s ease;
    transition: transform .3s ease, -webkit-transform .3s ease; }
  .arrow-link:hover {
    color: #FFBE01; }
    .arrow-link:hover:after {
      -webkit-transform: translateX(2px);
              transform: translateX(2px); }

/* flex-box stuff */
.flex {
  display: flex; }
  .flex.v-center {
    align-items: center; }
  .flex .flex-1 {
    flex: 1 1; }
  .flex.j-between {
    justify-content: space-between; }

/* ratio-box - a block that maintains aspect ratio. 1:1 by default*/
.ratio-box {
  position: relative; }
  .ratio-box:after {
    z-index: -1;
    content: '';
    padding-bottom: 100%;
    display: block; }
  .ratio-box.r16-9:after {
    padding-bottom: 56.25%; }
  .ratio-box .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

/* Button mixin */
.SingleEpisode {
  font-family: 'Work Sans', sans-serif; }
  .SingleEpisode .EpisodeLeft .ratio-box {
    max-width: 320px;
    max-height: 320px; }
    .SingleEpisode .EpisodeLeft .ratio-box img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 30px; }
  .SingleEpisode .EpisodeRight .showTitle {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    opacity: 0.6;
    margin-bottom: 4px; }
  .SingleEpisode .EpisodeRight .episodeTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: #FFFFFF;
    margin-bottom: 8px; }
  .SingleEpisode .EpisodeRight .episodeCategory {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF; }
    .SingleEpisode .EpisodeRight .episodeCategory .episodeDuration {
      font-family: 'Montserrat', sans-serif;
      color: #EEEEEE;
      font-weight: normal; }
  .SingleEpisode .EpisodeRight .StarRating {
    padding: 19px 0; }
  .SingleEpisode .EpisodeRight .episodeDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */
    /* Text color */
    color: #FFFFFF;
    padding-top: 5px; }
  .SingleEpisode .EpisodeRight .actions {
    padding-top: 21px; }
    .SingleEpisode .EpisodeRight .actions button {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      border: 0;
      justify-content: center;
      color: rgba(196, 196, 196, 0.6);
      background: transparent;
      mix-blend-mode: normal; }
      .SingleEpisode .EpisodeRight .actions button:active, .SingleEpisode .EpisodeRight .actions button:focus {
        outline: none; }
      .SingleEpisode .EpisodeRight .actions button i {
        font-size: 24px; }
      .SingleEpisode .EpisodeRight .actions button.playButton {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        border: 0;
        justify-content: center;
        color: #FFBE01;
        background: transparent;
        mix-blend-mode: normal; }
        .SingleEpisode .EpisodeRight .actions button.playButton:active, .SingleEpisode .EpisodeRight .actions button.playButton:focus {
          outline: none; }
        .SingleEpisode .EpisodeRight .actions button.playButton i {
          color: #FFBE01; }
  .SingleEpisode .EpisodeRight .episodeNav {
    padding-top: 39px; }
    .SingleEpisode .EpisodeRight .episodeNav button {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      border: 0;
      justify-content: center;
      color: #FFBE01;
      background: transparent;
      mix-blend-mode: normal;
      border-top: 1px solid;
      box-shadow: none;
      border-radius: 0;
      outline: 0;
      padding: 0; }
      .SingleEpisode .EpisodeRight .episodeNav button:active, .SingleEpisode .EpisodeRight .episodeNav button:focus {
        outline: none; }
      .SingleEpisode .EpisodeRight .episodeNav button i {
        font-size: 16px; }

.ant-row-flex .ant-col {
  align-self: stretch;
  margin-bottom: 23px; }

.Host {
  background: rgba(255, 255, 255, 0.1);
  mix-blend-mode: normal;
  box-shadow: 0px 2px 4px rgba(255, 190, 1, 0.1);
  border-radius: 8px;
  font-family: 'Work Sans', sans-serif;
  height: 100%;
  box-sizing: border-box; }
  .Host .card-header {
    display: flex; }
    .Host .card-header .ant-card-meta-avatar .ant-avatar-image {
      width: 120px;
      height: 120px; }
    .Host .card-header .ant-card-meta-detail .ant-card-meta-title {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      /* Main Color */
      color: #FFBE01;
      padding-top: 21px;
      margin-bottom: 12px; }
    .Host .card-header .ant-card-meta-detail .ant-card-meta-description {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      /* grey icon */
      color: rgba(196, 196, 196, 0.6); }
    .Host .card-header .SocialButtons {
      padding-top: 21px; }
      .Host .card-header .SocialButtons a {
        color: #fff;
        margin-right: 30px; }
        .Host .card-header .SocialButtons a .anticon svg {
          width: 24px;
          height: 24px; }
        .Host .card-header .SocialButtons a:last-child {
          margin-right: 0; }
  .Host .card-content {
    padding-top: 17px;
    margin-bottom: 14px; }
    .Host .card-content p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #FFFFFF;
      margin-bottom: 0; }
  .Host .related {
    display: flex;
    flex-wrap: wrap; }
    .Host .related a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      /* grey icon */
      color: rgba(196, 196, 196, 0.6);
      margin-right: 5px;
      white-space: nowrap;
      display: inline-block; }
      .Host .related a:hover {
        text-decoration: underline; }
      .Host .related a:after {
        content: ','; }
      .Host .related a:last-child:after {
        content: ''; }

