.__card {
    position: relative;
    border-radius: 30px;
    overflow: hidden;

    .__caption {
        background: inherit;
        position: relative;
        border-radius: 0 0 30px 30px;
        z-index: 2;

        // padding: 20px;
        &:before {
            content: '';
            display: block;
            height: 0;
            padding-bottom: 39.603960396%;
        }
    }
}


