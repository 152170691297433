@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,700,800&display=swap');

.ant-row-flex {

  .ant-col {
    align-self: stretch;
    margin-bottom: 23px;
  }

}


.Host {
  background: rgba(255, 255, 255, .1);
  mix-blend-mode: normal;
  box-shadow: 0px 2px 4px rgba(255, 190, 1, 0.1);
  border-radius: 8px;
  font-family: 'Work Sans', sans-serif;

  height: 100%;
  box-sizing: border-box;

  .card-header {

    display: flex;

    .card-header-left {}

    .card-header-right {}

    .ant-card-meta-avatar {
      .ant-avatar-image {
        width: 120px;
        height: 120px;
      }
    }

    .ant-card-meta-detail {
      .ant-card-meta-title {

        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;

        /* Main Color */
        color: #FFBE01;

        padding-top: 21px;
        margin-bottom: 12px;
      }

      .ant-card-meta-description {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        /* grey icon */
        color: rgba(196, 196, 196, 0.6);
      }
    }

    .SocialButtons {
      padding-top: 21px;

      a {
        color: #fff;
        margin-right: 30px;

        .anticon svg {
          width: 24px;
          height: 24px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }


  }

  .card-content {
    padding-top: 17px;
    margin-bottom: 14px;

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #FFFFFF;
      margin-bottom: 0;
    }
  }

  .related {
    display: flex;
    flex-wrap: wrap;

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      /* grey icon */
      color: rgba(196, 196, 196, 0.6);
      margin-right: 5px;

      white-space: nowrap;
      display: inline-block;

      &:hover {
        text-decoration: underline
      }

      &:after {
        content: ','
      }
      &:last-child:after{
        content: ''
      }

    }
  }
}
