@import '../../scss/vars';

.FeaturedEpisode {
  transition: all ease .3s;
  border: 1px solid $bg;
  &:hover {
    border-color: $primary;
  }
  .glass {
    padding: 20px;
    border-radius: 0 0 28px 28px;
  }
  .ep-caption {
    .duration {
      margin-left: 10px;
    }
  }
}