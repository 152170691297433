@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import '../../scss/_utils.scss';


.SingleEpisode {
  font-family: 'Work Sans', sans-serif;

  .EpisodeLeft {

    .ratio-box {
      max-width: 320px;
      max-height: 320px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
      }
    }

  }

  .EpisodeRight {
    .showTitle {
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
      opacity: 0.6;
      margin-bottom: 4px;
    }

    .episodeTitle {
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 30px;
      color: #FFFFFF;
      margin-bottom: 8px;
    }

    .episodeCategory {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;

      .episodeDuration {
        font-family: 'Montserrat', sans-serif;
        color: #EEEEEE;
        font-weight: normal;
      }
    }

    .StarRating {
      padding: 19px 0;
    }

    .episodeDescription {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */
      /* Text color */
      color: #FFFFFF;
      padding-top: 5px;
    }

    .actions {
      padding-top: 21px;

      button {
        @include button('gray') i {
          font-size: 24px;
        }

        &.playButton {
          @include button('transparent-yellow');

          i {
            color: #FFBE01;
          }
        }
      }
    }

    .episodeNav {
      padding-top: 39px;

      button {
        @include button('transparent-yellow');

        border-top: 1px solid;
        box-shadow: none;
        border-radius: 0;
        outline: 0;
        padding: 0;

        i {
          font-size: 16px;
        }

      }
    }
  }


}
