.Search {
  position: relative;
  margin-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  .open & {
    cursor: initial;
  }
  .magnifier {
    pointer-events: none;
    position: absolute;
    left: 13px;
    top: 3px;
    z-index: 1;
  }
  .ant-select {
    display: block;
    &-selection {
      background-color: transparent;
      opacity: 0;
      .open & {
        opacity: 1;
      }
      &__placeholder {
        left: 30px;
      }
    }
  }
  .ant-select-auto-complete.ant-select {
    .ant-input {
      background: none;
      color: #fff;
      padding-left: 40px;
    }
  }
}