.carousel.carousel-slider {

  padding-top: 46px;
  margin-top: -38px;

  .control-arrow {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    background-size: contain;
    position: absolute;
    top: 0;

    &:before {
      display: none;
    }

    &:hover {
      background: inherit;
      background-repeat: no-repeat;
      background-position: center;
      background-color: transparent;
      background-size: contain;
    }

    &.control-prev {
      right: 62px;
      left: auto;
      background-image: url('../../assets/left-circle.svg');
    }

    &.control-next {
      right: 0;
      background-image: url('../../assets/right-circle.svg');
    }

  }
}
