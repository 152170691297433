@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,600,700&display=swap');

#player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: -1px 0 10px rgba(#000, .3);
  color: white;
  padding: 30px;
}


.TrackInfo {
  background: transparent;
  flex-shrink: 0;

  .ant-card-body {
    padding: 0;
  }

  .ant-card-meta-avatar {
    .ant-avatar {
      box-shadow: 0px 1px 4px rgba(4, 249, 171, 0.15);
      border-radius: 8px;
      width: 64px;
      height: 64px;
    }
  }

  .ant-card-meta-detail {
    .ant-card-meta-title {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      color: #FFFFFF;
    }

    .ant-card-meta-description {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      color: #FFFFFF;
      margin-top: -10px;
    }
  }

}


.PlayerControls {
  display: flex;
  padding-left: 4vw;
  padding-top: 15px;

  .ProgressBar {
    width: 37vw;
    margin: 0 15px;
    padding-top: 3px;

    .ant-progress-inner {
      background: #C4C4C4;
      border-radius: 5px;
      height: 6px;

      .ant-progress-bg {
        background: #FFBE01;
        mix-blend-mode: normal;
        border-radius: 5px;
      }
    }

  }

  .PlayButton {
    margin: 0 20px;
  }

  .TimeElapsed,
  .TimeDuration {
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #EEEEEE;
    padding-top: 7px;
    width: 30px;
    text-align: center;
  }

  .TimeElapsed {
    margin-left: 35px;
  }

  .Prev10,
  .PlayButton,
  .Next10,
  .DownloadButton,
  .ShareButton {
    display: flex;
    justify-content: center;
    background: transparent;
    border: 0;
    color: #FFBE01;
    font-size: 32px;
  }

  .DownloadButton {
    font-size: 24px;
  }

  .ShareButton {
    color: #EEEEEE;
    font-size: 20px;
  }

  .DownloadButton {
    margin-left: 28px;
    margin-right: 16px;
  }

}

.PlayerAudio {
  display: none;
}
