@import '../../scss/vars';

Nav {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
  overflow: auto;
  .anticon {font-size: 22px;}
  main {
    flex: 1;
    padding-top: 100px;
  }
  footer {
    padding-bottom: 30px;
    .toggle {
      padding-left: 30px;
    }
  }
  .ant-menu.ant-menu-vertical {
    padding: 0 15px;
    background-color: transparent;
    border-right: none;
    .ant-menu-item {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 22px;
      i {
        margin-right: 20px;
        font-size: 22px;
      }
      &:active {background: transparent;}
      a:hover {
        color: $primary;
      }
      &-selected {
        background: transparent;
        a {
          color: $primary;
        }
      }
    }

    a {color: #fff;}
  }
  .divider {
    margin: 30px 0;
  }
}